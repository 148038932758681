/** @jsx jsx */
import { jsx } from 'theme-ui';
import { keyframes } from '@emotion/react';

const fade = keyframes({ from: { opacity: 0.6 }, to: { opacity: 1 } });

const Letter = ({ children, top, left }) => {
	const style = {
		pointerEvents: 'none',
		position: 'absolute',
		zIndex: 1000,
		fontSize: '15vw',
		zIndex: 999,
		color: '#fff',
		display: 'inline-block',
		fontFamily: 'Oswald',
		letterSpacing: '4rem',
		fontWeight: 400,
		textTransform: 'uppercase',
		textShadow: '0 -1px 0 #00f, 0 1px 0 #00f, 1px 0 0 #00f, -1px 0 0 #00f, -6px 4px 0 #00f'
	};
	return <span sx={{ ...style, left: `${left}%`, top: `${top - 10}%` }}>{children}</span>;
};
const Letters = () => (
	<div sx={{ animation: `${fade} 3s ease-in infinite`, animationDirection: 'alternate' }}>
		<Letter {...{ left: 24.6875, top: 33.25 }}>L</Letter>
		<Letter {...{ left: 30.9375, top: 36 }}>A</Letter>

		<Letter {...{ left: 41.1719, top: 35.5 }}>F</Letter>
		<Letter {...{ left: 46.4062, top: 42.5 }}>L</Letter>
		<Letter {...{ left: 51.7969, top: 46.25 }}>E</Letter>
		<Letter {...{ left: 58.0469, top: 43.625 }}>U</Letter>
		<Letter {...{ left: 65.9375, top: 36.375 }}>R</Letter>
	</div>
);

export { Letters };
